<template>
  <div>
      <!-- <barcode :value="132524234" :tag="tag"  :options="options"></barcode>
      <qrcode value="https://www.innotechlab.co.th" />
      <gmap-map
        :center="{ lat: 13.731062, lng: 100.641657 }"
        :zoom="15"
        style="width:100%;  height: 400px;"
      >
        <gmap-marker
          :position="{ lat: 13.731062, lng: 100.641657 }"
        ></gmap-marker>
      </gmap-map> -->
      <DayPilotScheduler id="dp" :config="config" ref="scheduler" />
  </div>
</template>

<script>
import {DayPilot, DayPilotScheduler} from 'daypilot-pro-vue'
// import GoogleMap from '@/components/GoogleMap'

export default {
  name: 'Scheduler',
  components: {
    DayPilotScheduler
  },
  data: function () {
    return {
      options: {
        lineColor: '#ff7069',
        fontSize: 32,
        font: 'Courier',
        width: 3,
        height: 60,
        marginBottom: 60,
        format: 'MSI',
        background: '#ccffff'
      },
      config: {
        timeHeaders: [
          {groupBy: 'Month'},
          {groupBy: 'Day', format: 'd'},
          {groupBy: 'Hour', format: 'h'}
        ],
        scale: 'Hour',
        cellWidth: 5,
        // startDate: DayPilot.Date.today().firstDayOfMonth(),
        // days: DayPilot.Date.today().daysInMonth(),
        startDate: '2019-10-04T08:00:00',
        days: 31,
        resources: [
          {name: 'Resource 1', id: 'R1'},
          {name: 'Resource 2', id: 'R2'},
          {name: 'Resource 3', id: 'R3'},
          {name: 'Resource 4', id: 'R4'},
          {name: 'Resource 5', id: 'R5'},
          {name: 'Resource 6', id: 'R6'},
          {name: 'Resource 7', id: 'R7'},
          {name: 'Resource 8', id: 'R8'},
        ],
        events: [
          {id: 1, resource: 'R1', start: '2019-10-04T08:00:00', end: '2019-10-05T14:00:00', text: 'Event 1', barColor: '#1155cc'},
          {id: 2, resource: 'R2', start: '2019-10-04T18:00:00', end: '2019-10-05T10:00:00', text: 'Event 2', barColor: '#6aa84f'},
          {id: 3, resource: 'R1', start: '2019-10-03T22:00:00', end: '2019-10-05T16:00:00', text: 'Event 3', barColor: '#f1c232'},
          {id: 4, resource: 'R4', start: '2019-10-05T06:00:00', end: '2019-10-05T22:00:00', text: 'Event 4', barColor: '#c3de21'},
          {id: 5, resource: 'R2', start: '2019-10-05T14:00:00', end: '2019-10-06T16:00:00', text: 'Event 5', barColor: '#a2df8e'},
          {id: 6, resource: 'R5', start: '2019-10-04T10:00:00', end: '2019-10-05T22:00:00', text: 'Event 6', barColor: '#e1d53c'},
          {id: 7, resource: 'R7', start: '2019-10-04T16:00:00', end: '2019-10-06T20:00:00', text: 'Event 7', barColor: '#e8e53c'},
        ],
        scrollTo: DayPilot.Date.today()
      },
    }
  },
  methods: {
  },
  computed: {
    // DayPilot.Scheduler object - https://api.daypilot.org/daypilot-scheduler-class/
    scheduler: function () {
      return this.$refs.scheduler.control
    }
  },
  mounted: function () {
  }
}
</script>

<style scoped>
</style>
